<template>
  <div>
    <v-row class="d-flex mx-0">
      <v-col cols="3" md="4" lg="3">
        <v-text-field
          outlined
          dense
          clearable
          style="border-radius: 8px"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('search')"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        @toggle-select-all="getSelectAll"
        :items="items"
        item-key="userID"
        :search="search"
        class="department-table"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top> </template>
      </v-data-table>

      <v-row justify="end">
        <div class="ma-5 d-flex">
          <v-pagination
            v-model="page"
            class="pagination"
            :length="pageCount"
          ></v-pagination>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="150"
                v-bind="attrs"
                v-on="on"
                class="text-capitalize mt-1"
                outlined
              >
                {{ itemsPerPage }} / page <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in paginationCount"
                :key="index"
                @click="itemsPerPage = item.title"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>

      <v-row justify="end" class="ma-2">
        <v-btn
          text
          outlined
          class="text-capitalize mr-3"
          style="font-size: 20px"
          @click="
            $store.commit('DepartmentTable', false),
              $store.commit(
                editDepartment
                  ? 'openEditDepartmentDialog'
                  : 'openAddDepartmentDialog'
              ),
              $store.commit('saveSearchandBtnFlag', true)
          "
          >{{ $t("cancel") }}</v-btn
        >
        <!-- $store.commit(editDepartment ? '' :'clearSelected'),text; -->
        <v-btn          
          color="secondary"
          class="text-capitalize white--"
          style="font-size: 20px"
          @click="
            $store.commit('DepartmentTable', false),         
            $store.commit(
              editDepartment
                ? 'openEditDepartmentDialog'
                : 'openAddDepartmentDialog'
            ),
              $store.commit('saveSelectedDepartmentItem', selected),
              $store.commit('saveSearchandBtnFlag', true);
          "
          >{{ $t("save") }}</v-btn
        >
      </v-row>
    </v-container>
    <Edit
      :show="editDialog"
      @close="editDialog = false"
      @confirm="
        (editDialog = false), (showMessage.show = true), getUserDataInGroup()
      "
    />

    <DeleteConfirmDialog
      :show="confirmMessage.show"
      :text="message"
      @close="confirmMessage.show = false"
      @confirm="deleteConfirm()"
    />

    <SuccessDialog
      :show="showMessage.show"
      :title="showMessage.title"
      :text="showMessage.text"
      @close="showMessage.show = false"
    />
  </div>
</template>

<script>
import Edit from "./../User/Edit.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Edit,
  },
  data: () => ({
    loading: false,
    editDialog: false,
    search: null,
    //items: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      {
        title: 5,
      },
      {
        title: 10,
      },
      {
        title: 20,
      },
      {
        title: 30,
      },
      {
        title: 40,
      },
    ],
    selected: [],
    showMessage: {
      show: false,
      title: "Success",
      text: "Deleting User is successful.",
    },
    confirmMessage: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters([
      "userEditData",
      "editDepartment",
      "editDepartmentData",
      "selectedMemberList",
      "UserList",
    ]),
    headers() {
      return [
        {
          text: this.$t("userid"),
          value: "profileID",
          class: "tableColor",
        },
        {
          text: this.$t("fullnamesm"),
          align: "center",
          value: "fullName",
          class: "tableColor",
        },
        {
          text: this.$t("email"),
          align: "center",
          value: "email",
          class: "tableColor",
        },
        {
          text: this.$t("ph"),
          align: "center",
          value: "mobile",
          class: "tableColor",
        },
        {
          text: this.$t("departmentl"),
          align: "center",
          value: "department",
          class: "tableColor",
        },
        // {
        //   text: this.$t("levelC"),
        //   align: "center",
        //   value: "levelObject.levelName",
        //   class: "tableColor",
        // },
        // {
        //   text: this.$t("manage"),
        //   align: "center",
        //   value: "actions",
        //   class: "tableColor",
        // },
      ];
    },
    items: {
      get() {
        return this.UserList;
      },
    },
    message: {
      get() {
        return (
          'Are you sure you want to delete "user ID ' +
          this.userEditData.empID +
          " ?"
        );
      },
    },
  },
  mounted() {
    this.getSelected();
  },
  methods: {
    getSelectAll() {
      if (this.selected.length == 0) {
        this.selected = this.items;
      } else {
        this.selected = [];
      }
    },
    async getUserData() {
      this.loading = true;
      const res = await this.$axios.get(
        `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
          localStorage.getItem("companyID")
      );
      this.items = res.data.data;
      this.loading = false;
    },

    getSelected() {
      this.selected = this.selectedMemberList;
    },

    async deleteConfirm() {
      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/DeleteEmployeeExt?id=` +
          this.userEditData.userID
      );
      if (res) {
        this.confirmMessage.show = false;
        this.showMessage.show = true;
        this.getUserDataInGroup();
      }
    },
  },
};
</script>
<style scoped>
/* >>> .department-table tr:nth-child(even) {
  background: #e0e0e0;
} */
::v-deep .v-input--selection-controls__input .v-icon {
  color: #a6cc39 !important;
  border-radius: 4px;
  opacity: 1;
}
::v-deep .v-text-field__slot {
  font-size: 20px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 20px !important;
  color: #47484b !important;
  background-color: #dbdbda !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 20px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #e8e9ec !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
</style>

<style>
.department-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.department-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>
