<template>
  <div>
    <v-dialog v-model="editDialog" width="800" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="ma-1" style="font-size: 28px; color: #424242"
              >mdi-account-plus-outline</v-icon
            >
            {{ $t("edituser") }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $emit('close'), (showRoleAlert = false), (FlagGenLink = false)
            "
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px" v-if="userEditData">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <div class="d-flex">
                  <p class="pt-5" style="font-size: 20px; color: #a6cc39">
                    {{ $t("userinformation") }}
                  </p>

                  <div class="editonoffswitch mt-4 ml-5">
                    <input
                      type="checkbox"
                      name="editonoffswitch"
                      class="editonoffswitch-checkbox"
                      id="editmyonoffswitch"
                      checked
                      v-model="userEditData.lock"
                    />
                    <label
                      class="editonoffswitch-label"
                      for="editmyonoffswitch"
                    >
                      <div class="editonoffswitch-inner"></div>
                      <div class="editonoffswitch-switch"></div>
                    </label>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="secondary"></v-divider>
            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="employeeId" class="font-style">
                  {{ $t("employeeId") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="userRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.empID"
                  class="mt-2"
                ></v-text-field>
                <span v-if="isEmpidExist" style="color: red" class="text"
                  >Employee ID already exist</span
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="prefix" class="font-style">{{
                  $t("prefix")
                }}</label>
                <v-combobox
                  class="mt-2"
                  :items="prefixItems"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.prefix"
                ></v-combobox>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="firstname" class="font-style">
                  {{ $t("firstname") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="firstNameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.firstName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="lastname" class="font-style">
                  {{ $t("lastname") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="lastNameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.lastName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="email" class="font-style">
                  {{ $t("email") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="emailRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.email"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="loading-circle"
                color="#A6CC39"
              ></v-progress-circular>

              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="telephone" class="font-style">
                  {{ $t("telephone") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="phoneNumberRules"
                  v-mask="'###########'"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.mobile"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="lineId" class="font-style">
                  {{ $t("lineId") }}
                </label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.lineID"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="group" class="font-style">{{ $t("group") }}</label>
                <v-select
                  :items="editdeptList"
                  item-text="groupName"
                  item-value="groupID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.employeeGroup"
                  label="Please Select"
                  class="mt-2"
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAllGroup"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            userEditData.employeeGroup
                              ? userEditData.employeeGroup.length > 0
                                ? '#a6cc39'
                                : ''
                              : ''
                          "
                          >{{ icon_dpt }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.groupName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{
                        userEditData.employeeGroup.length - 1
                      }}
                      others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="departmentl" class="font-style">
                  {{ $t("departmentl") }}
                </label>

                <v-select
                  :items="filteredNames"
                  item-text="departmentName"
                  item-value="departmentId"
                  outlined
                  :search="search"
                  dense
                  hide-details="auto"
                  label="Please Select"
                  class="mt-2"
                  clearable
                  v-model="userEditData.departmentId"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="search"
                          :placeholder="$t('search')"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item class="custom-border">
                      <v-list-item-content style="border-radious=10px">
                        <v-list-item-title>{{
                          item.departmentName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.departmentName }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="role" class="font-style">
                  {{ $t("role") }}
                  <span style="color: red">*</span>
                </label>
                <v-select
                  :rules="roleNameRules"
                  :items="levelList"
                  item-text="roleName"
                  item-value="userRoleID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.employeeRole"
                  label="Please Select"
                  @input="checkRole(userEditData.employeeRole)"
                  class="mt-2"
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAllRole"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            userEditData.employeeRole
                              ? userEditData.employeeRole.length > 0
                                ? '#a6cc39'
                                : ''
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.roleName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{
                        userEditData.employeeRole.length - 1
                      }}
                      others)</span
                    >
                  </template>
                </v-select>
                <span v-if="showRoleAlert" style="color: red">{{
                  "Please select a role."
                }}</span>
              </v-col>
              <v-col cols="6">
                <label for="gender" class="font-style">{{
                  $t("gender")
                }}</label>
                <v-radio-group row v-model="userEditData.gender">
                  <v-radio :label="$t('male')" value="male"></v-radio>
                  <v-radio :label="$t('female')" value="female"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label for="sendnotification" class="font-style">{{
                  $t("sendnotification")
                }}</label>
                <v-checkbox
                  v-model="user.EmailCheck"
                  :label="$t('email')"
                  color="secondary"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col cols="12">
                <p style="font-size: 20px; color: #a6cc39">
                  {{ $t("logincred") }}
                </p>
                <v-divider class="secondary"></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="account" class="font-style">
                  {{ $t("account") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="accountRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="userEditData.username"
                  class="mt-2"
                ></v-text-field>
                <span v-if="isExist" style="color: red" class="text"
                  >This username is already taken</span
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="password" class="font-style">
                  {{ $t("password") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  dense
                  hide-details="auto"
                  append-icon="mdi-lock"
                  v-model="user.password"
                  placeholder="******"
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 mb-5">
          <!-- {{items}} -->
          <v-btn
            v-if="!FlagGenLink"
            class="text-capitalize"
            color="secondary"
            @click="GenLink()"
            >{{ $t("GenLink") }}</v-btn
          >
          <v-textarea
            v-if="FlagGenLink"
            v-on:focus="$event.target.select()"
            ref="Url"
            v-model="Url"
            outlined
            no-resize
            rows="3"
            style="margin-right: 16px"
          ></v-textarea>
          <p id="Url_copy" style="margin-right: 16px; display: none">
            {{ Url_copy }}
          </p>
          <v-btn
            v-if="FlagGenLink"
            class="text-capitalize mr-3"
            color="secondary"
            :hide="true"
            @click="copy()"
            >{{ $t("copy") }}</v-btn
          >

          <v-spacer v-if="!FlagGenLink"></v-spacer>

          <v-btn
            text
            outlined
            class="text-capitalize mr-3"
            @click="
              $emit('close'),
                (showRoleAlert = false),
                (isEmpidExist = false),
                (isExist = false),
                (FlagGenLink = false)
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            color="secondary"
            :disabled="isdisabledbutton"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    oldLock: Boolean,
    //ActiveEmployeeCount:Number
  },
  computed: {
    ...mapGetters([
      "userEditData",
      "levelList",
      "editdeptList",
      "UserList",
      "editdeptmarentList",
    ]),
    filteredNames() {
      return this.Departmentitems.filter((x) =>
        x.departmentName.toLowerCase().includes(this.search.toLowerCase())
      );
    },

    userlist: {
      get() {
        return this.UserList;
      },
    },

    //for group/department
    selectAllGroup() {
      return this.userEditData.employeeGroup
        ? this.userEditData.employeeGroup.length === this.editdeptList.length
        : 0;
    },
    selectAllDep() {
      return this.userEditData.employeeDep
        ? this.userEditData.employeeDep.length ===
            this.editdeptmarentList.length
        : 0;
    },
    icon_dpt() {
      if (this.selectAllGroup) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    //for role
    selectAllRole() {
      return this.userEditData.employeeRole
        ? this.userEditData.employeeRole.length === this.levelList.length
        : 0;
    },
    icon() {
      if (this.selectAllRole) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },

    isLock: {
      get() {
        return this.userEditData.lock;
      },
      set(e) {
        this.isLock = e;
      },
    },
    editDialog: {
      get() {
        return this.show;
      },
      set() {
        this.user.EmailCheck = true;
        this.$emit("close");
      },
    },
    // selectedLevel: {
    //   get() {
    //     if (this.userEditData.levelObject) {
    //       return this.userEditData.levelObject.levelName;
    //     } else {
    //       return false;
    //     }
    //   },
    //   set(e) {
    //     //this.user.level = e;
    //     this.userEditData.level = e;
    //   }
    // },
    userRules() {
      return [(v) => !!v || "Employee ID is required"];
    },
    prefixRules() {
      return [(v) => !!v || "Prefix is required"];
    },
    firstNameRules() {
      return [(v) => !!v || "First name is required"];
    },
    lastNameRules() {
      return [(v) => !!v || "last name is required"];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    phoneNumberRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) =>
          (v && v.length >= 10) ||
          "Phone Number must be greather than 10 characters",
        (v) =>
          (v && v.length <= 11) ||
          "Phone Number must be less than 11 characters",
      ];
    },
    roleNameRules() {
      return [(v) => v?.length > 0 || "Please select a role."];
    },
    genderRules() {
      return [(v) => !!v || "Gender is required"];
    },
    accountRules() {
      return [(v) => !!v || "Username is required"];
    },
  },
  data: () => ({
    isEmpidExist: false,
    isExist: false,
    showRoleAlert: false,
    valid: false,
    search: "",
    prefixItems: ["Mr.", "Ms.", "Mrs.", "Miss."],
    levels: [],
    departmentlist: [],

    departments: [],
    Departmentitems: [],
    user: {
      //level: "",
      level: [],
      //employeeRole: [],
      password: "",
      EmailCheck: false,
      RadioCheck: false,
    },
    showPassword: "password",
    deptlist: [],
    PricingData: [],
    ActiveEmployeeCount: 0,
    loading: false,
    isdisabledbutton: false,
    FlagGenLink: false,
    Url: "",
    Url_copy: "",
    items: [],
  }),
  mounted() {
    this.getGroupAll();
    this.getLevel();
    this.getPricingData();
    this.getCountForEmployee();
    this.getDepartmentList();
  },

  watch: {
    search() {
      this.searchMeth();
    },
  },
  methods: {
    checkRole(v) {
      if (v.length == 0) {
        this.showRoleAlert = true;
      } else {
        this.showRoleAlert = false;
      }
    },
    async searchMeth() {
      if (this.search == "") {
        this.departmentlist = this.Departmentitems;
      } else {
        this.departmentlist = this.Departmentitems.filter((name) =>
          name.departmentName.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },

    getSelectAllGroup() {
      if (this.userEditData.employeeGroup.length == 0) {
        //departmentID //uGroupID
        this.userEditData.employeeGroup = this.editdeptList.map(
          ({ groupID }) => groupID
        );
      } else {
        this.userEditData.employeeGroup = [];
      }
    },
    getSelectAllDep() {
      if (this.userEditData.employeeDep.length == 0) {
        //departmentID //uGroupID
        this.userEditData.employeeDep = this.editdeptmarentList.map(
          ({ departmentId }) => departmentId
        );
      } else {
        this.userEditData.employeeDep = [];
      }
    },
    selectItem(itemId) {
      this.selectedItem = itemId;
    },

    getSelectAllRole() {
      if (this.userEditData.employeeRole.length == 0) {
        this.userEditData.employeeRole = this.levelList.map(
          ({ userRoleID }) => userRoleID
        );
      } else {
        this.userEditData.employeeRole = [];
      }
    },
    ChkUsernameAndID() {
      let temp = [];
      this.userlist.forEach((v) => {
        if (
          v.empID == this.userEditData.empID ||
          v.username == this.userEditData.username
        ) {
          temp.push(v);
        }
      });

      if (temp.length > 1) {
        let otherUser_temp = temp.filter(
          (v) => v.employeeID != this.userEditData.employeeID
        ); //users that use this info first >> can be more than 1

        if (otherUser_temp.find((a) => this.userEditData.empID == a.empID)) {
          this.isEmpidExist = true;
        } else {
          this.isEmpidExist = false;
        }
        if (
          otherUser_temp.find((b) => this.userEditData.username == b.username)
        ) {
          this.isExist = true;
        } else {
          this.isExist = false;
        }
      } else {
        if (temp[0].employeeID == this.userEditData.employeeID) {
          //there is no duplicate username or empID in this condition..
          //its same user updating.
          this.isExist = false;
          this.isEmpidExist = false;
        } else {
          if (this.userEditData.empID == temp[0].empID) {
            this.isEmpidExist = true;
          } else {
            this.isEmpidExist = false;
          }
          if (this.userEditData.username == temp[0].username) {
            this.isExist = true;
          } else {
            this.isExist = false;
          }
        }
      }
    },
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getCountForEmployee() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      this.ActiveEmployeeCount = res.data.data[0].activeEmployee;
    },
    async getDepartmentList() {
      const res = await this.$axios.get(
        `${this.web_url}Department/GetDepartmentList?id=` +
          localStorage.getItem("companyID")
      );
      this.Departmentitems = res.data.data;
      this.departmentlist = this.Departmentitems;
      this.$store.commit("savedeparmentList", res.data.data);
    },
    async getGroupAll() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      for (const element of res.data.data) {
        this.departments.push({
          groupID: element["groupID"],
          groupName: element["groupName"],
        });
        delete element.groupID;
        delete element.groupName;
      }
      this.$store.commit("editeparmentList", this.departments);
    },

    async getLevel() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.levels = res.data.data;
      this.$store.commit("saveroleList", res.data.data);
    },

    async submit() {
      let self = this;
      if (self.$refs.form.validate()) {
        if (self.oldLock) {
          self.EditFunction();
        } else {
          if (self.userEditData.lock === false) {
            self.EditFunction();
          } else {
            if (self.ActiveEmployeeCount < self.PricingData.activeEmpId) {
              self.EditFunction();
            } else {
              alert(
                "The active employees are reach limit amount. Contact Seedkm administrator to enhance usage"
              );
              self.user = {};
              self.user.EmailCheck = true;
              self.$refs.form.resetValidation();
              self.editDialog = false;
            }
          }
        }
      } else {
        if (this.userEditData.employeeRole.length == 0) {
          this.showRoleAlert = true;
        } else {
          this.showRoleAlert = false;
        }
      }
    },
    async EditFunction() {
      this.ChkUsernameAndID();
      //check duplicate empId && account name..
      if (this.isExist === true || this.isEmpidExist === true) {
        return false;
      }

      this.loading = true;
      this.isdisabledbutton = true;
      this.showRoleAlert = false;
      let empGroup = [];
      let empRole = [];

      if (this.userEditData.employeeGroup != null) {
        for (const element of this.userEditData.employeeGroup) {
          empGroup.push({
            updateBy: localStorage.getItem("userName"),
            employeeID: 0,
            groupID: element.groupID ? element.groupID : element,
          });
        }
      }

      if (this.userEditData.employeeRole != null) {
        for (const element of this.userEditData.employeeRole) {
          empRole.push({
            updateBy: localStorage.getItem("userName"),
            employeeID: 0,
            roleID: element.userRoleID ? element.userRoleID : element,
          });
        }
      }

      const emp_data = {
        createBy: "",
        updateBy: localStorage.getItem("userName"), //"",
        companyId: localStorage.getItem("companyID"),

        employeeEXTId: this.userEditData.employeeID,
        username: this.userEditData.username,
        password: this.user.password ? this.user.password : "",
        agentID: 0,
        roleId: 0,
        userType: this.userEditData.userType,
        idcard: 0,
        gender: this.userEditData.gender,
        prefix: this.userEditData.prefix,
        firstName: this.userEditData.firstName,
        lastName: this.userEditData.lastName,
        fullName: "",
        mobile: this.userEditData.mobile,
        lineID: this.userEditData.lineID,
        email: this.userEditData.email,

        department: "",
        levels: "",
        pathImage: "",
        updatedByUserId: "",
        salaryid: 0,
        startWorkDate: "",
        supervisorID: 0,
        userRole: 0,
        positions: "",
        empID: this.userEditData.empID,
        departmentId: this.userEditData.departmentId,

        lockstatus: false,
        isEnable: this.userEditData.isEnable,
        lock: this.userEditData.lock,
        employeeGroup: empGroup,
        employeeRole: empRole,
        emailCheck: this.user.EmailCheck,
      };

      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/UpdateEmployeeExt`,
        emp_data
      );
      if (res.data.status == 0) {
        this.user = {};
        const auth = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        auth.fullName =
          emp_data.prefix + " " + emp_data.firstName + " " + emp_data.lastName;
        localStorage.setItem(
          "vivek_authenticated_user_data",
          JSON.stringify(auth)
        );
        localStorage.setItem("userName", JSON.stringify(auth.fullName));
        this.user.EmailCheck = false;
        this.isEmpidExist = false;
        this.isExist = false;
        this.$refs.form.resetValidation();
        this.getPricingData();
        this.getCountForEmployee();

        this.isdisabledbutton = false;
        this.FlagGenLink = false;
        this.$emit("confirm");
      }
      this.loading = false;
    },
    async GenLink() {
      let self = this;

      await self.$axios
        .post(
          `${self.web_url}Deeplink/GenDeeplinkURL?employeeid=` +
            self.userEditData.memberID
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.items = response.data.data;
            self.Url = self.items.linkURL;
            self.Url_copy =
              "โปรดคลิกลิงค์ที่แนบไว้ผ่านบนมือถือ เพื่อทำการยืนยันหมายเลขโทรศัพท์ 6 หลักสุดท้ายของท่านที่อยู่ในระบบ  \n**กรณีที่ยังไม่ได้ดาวน์โหลดแอพพลิเคชั่น Seed โปรดดาวน์โหลดก่อนคลิก \n\n" +
              self.items.linkURL;

            self.FlagGenLink = true;
          }
        })
        .catch(function (err) {
          alert(err);
          throw err;
        });
    },
    async copy() {
      let self = this;

      self.$refs.Url.focus();

      let text = document.getElementById("Url_copy").innerHTML;
      await navigator.clipboard.writeText(text);
    },
  },
};
</script>
<style scoped>
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.editonoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.editonoffswitch-checkbox {
  display: none;
}

.editonoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.editonoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.editonoffswitch-inner:before,
.editonoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.editonoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.editonoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.editonoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-inner {
  margin-left: 0;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-switch {
  right: 0px;
}
/* end switch */
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
}
::v-deep .v-btn__content {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>
